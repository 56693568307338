.authorizedList {
  padding: 0 4rem;

  @media screen and (max-width: 425px) {
      text-align: center;
      padding: 0 2rem;
  }
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;

  & > h6 {
    margin-block-start: 0;
    margin-block-end: 0;

    font-size: 20px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #000000;
  }

  & > p {
    margin-block-start: 0;
    margin-block-end: 0;

    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: #515151;
  }
}

.listContainer:first-child {
  margin-top: 2rem;
}