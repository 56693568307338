.header {
    z-index: 998;
    top: 0;
    position: fixed;
    width: 100%;
    height: 6rem;
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    color: white;
    background: linear-gradient(to right, #a7c04d, #e0ec53);
    gap: 1rem;
  }

  .header img {
    margin-right: 5rem;
    cursor: pointer;
  }
  
  .header2 {
    z-index: 999;
    top: 0;
    right: 200%;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    gap: 3rem;
    align-items: center;
    color: white;
    background: rgba(0, 0, 0, 0.88);
    transition: right ease-in-out 0.6s;
    
    .buttonPlan {
      margin-left: 35px;
    }
  }

  .mobileHeader {
      right: 0;
  }
  
  .headerRight {
    display: flex;
    gap: 1rem;
    padding-right: 2rem;
  }
  
  
  .buttonPlan {
    outline: none;
    border: none;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background: #ff7f1f;
    color: white;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 600px) {
    .headerRight {
      display: none;
    }
    .buttonMobile {
      outline: none;
      border: none;
      background: none;
      color: white;
      font-size: 1rem;
      display: flex !important;
      margin-right: 2rem;
    }
  }
  
  @media only screen and (max-width: 300px) {
    .buttonPlan{
      font-size: 0.6em;
    }
  }
  
  .buttonMobile {
    display: none;
  }
  
  
  