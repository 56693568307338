.wave {
  position: relative;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  bottom: -2px;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(133% + 1.3px);
  height: 237px;
  transform: rotateY(180deg);

  @media screen and (max-width: 490px) {
    width: calc(300% + 1.3px);
    height: 91px;
  }
}

.wave_fill {
  fill: #08824a;
}
