.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 6rem;
  margin-bottom: 6rem;

  @media screen and (max-width: 1360px) {
    padding: 0 2rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 1rem;
  }

  @media screen and (max-width: 500px) {
    margin-top: 3rem;
  }
}

.aboutColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.card {
  display: flex;
  width: 240px;
  height: 100%;
  background-color: #08824a;
  padding: 16px;
  border-radius: 16px 32px 16px 32px;
}

.row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.description {
  display: flex;
  max-width: 409px;

  @media screen and (max-width: 768px) {
    max-width: fit-content;
  }
}

.description p {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  line-height: 2.2rem;
  color: #225220;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
}

.column {
  display: flex;
  flex-direction: column;

  color: white;
  font-size: 32px;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9dc208;
  color: white;
  border: none;
  border-radius: 100%;
  width: 36px;
  height: 36px;
}

.imageContainer img {
  max-width: 580px;

  @media screen and (max-width: 1045px) {
    max-width: 500px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 400px;
  }

  @media screen and (max-width: 425px) {
    max-width: 300px;
  }
}

.cross {
  position: absolute;
  top: 80%;
  right: 0;
  width: 58%;
  z-index: -1;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
}