.testimonyContainer {
  display: flex;
  justify-content: center;
  padding: 0 5% 0 5%;

  @media screen and (min-width: 1360px) {
    padding: 0 15% 0 15%;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 580px) {
    padding: 0 5% 0 5%;
  }
}

.instagramContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  & > h4 {
    font-size: 48px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    color: white;
    text-align: center;
  }

  & > div > div > div:nth-child(2) {
    z-index: 999999;
  }

  & > div > div > div:nth-child(2) > div:nth-child(2) > div {
    border-radius: 20px;
  }
}

.instagramCard {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border-radius: 60px;

  width: 100%;
  height: 500px;
  margin-bottom: 7rem;

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > a > img {
    width: 100%;
    height: 400px;

    @media screen and (max-width: 580px) {
      height: 300px;
    }

    @media screen and (max-width: 425px) {
      height: 240px;
    }
  }

  @media screen and (max-width: 580px) {
    height: 350px;
  }
}

.instagramCard img{
  border-radius: 60px;
  width: 100%;
  height: 450px;

  @media screen and (max-width: 500px) {
    height: 300px;
    width: 100%;
  }

  @media screen and (max-width: 1280px) {
    height: 400px;
    width: 100%;
  }

  @media screen and (max-width: 1680px) {
    height: 400px;
    width: 100%;
    margin: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5rem;

  @media screen and (max-width: 1024px) {
    align-items: center;
  }

  @media screen and (max-width: 625px) {
    align-items: center;
  }
}

.aspas {
  width: 10%;
  
  @media screen and (max-width: 500px) {
    height: 20px;
  }
}

.testimony {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 325px;
  padding: 2rem;
  margin-top: 4rem;

  border-radius: 16px;
  background-color: #fff;

  @media screen and (max-width: 580px) {
    width: 240px;
    margin-top: 8rem;
  }

  & > p {
    max-width: 250px;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-style: italic;
    font-weight: 550;

    @media screen and (max-width: 580px) {
      max-width: 100%;
      margin-top: 4.5rem;
    }
  }
}

.author {
  display: flex;
  flex-direction: column;

  .name {
    font-size: 16px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #515151;
  }

  .location {
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: #3daa43;
    text-transform: uppercase;
  }
}

.profileImage {
  position: absolute;
  background: #fff;
  top: 13px;
  right: -80px;
  width: 190px;
  height: 190px;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 175px;
  }

  @media screen and (max-width: 580px) {
    top: -90px;
    right: 50px;
  }
}
