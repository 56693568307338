.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 223vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  @media screen and (max-width: 1024px) {
    width: 98%;
    top: 55%;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    top: calc(55% + 100px);
  }
}

.modal {
  width: 950px;
  height: 700px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px 0 0 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow-y: auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    height: calc(100% - 96px);
    max-height: 100vh;
  }
}

.modalHeader {
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  margin-top: 3rem;
  margin-left: 4rem;

  @media screen and (max-width: 425px) {
    margin-left: 0;
    text-align: center;
  }
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.modal::-webkit-scrollbar {
  width: 8px;
}

.modal::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}

.modal::-webkit-scrollbar-thumb {
  background-color: #4CAF50;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}
