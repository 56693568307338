.info {
  position: relative;
  background: #08824a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  margin-top: -0.5rem;
  padding-top: 6rem;

  @media screen and (max-width: 500px) {
    padding-top: 2rem;
  }
}

.coracao {
  width: 20%;
  position: absolute;
  top: 20%;
  right: 10%;
  z-index: 9;

  @media screen and (max-width: 1024px) {
    width: 25%;
    right: 5%;
  }

  @media screen and (max-width: 600px) {
    top: 15%;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.card {
  display: flex;
  width: 240px;
  height: 100%;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px 32px 16px 32px;
  margin-left: 1rem;
}

.row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.description {
  display: flex;
  flex-direction: column;
  max-width: calc(1000px - 2rem);
  padding: 0 2rem;

  @media screen and (max-width: 768px) {
    max-width: fit-content;
  }
}

.description p {
  font-size: 24px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  line-height: 2.2rem;
  color: #fff;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  color: #08824a;
  font-size: 32px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9dc208;
  color: white;
  border: none;
  border-radius: 100%;
  width: 36px;
  height: 36px;
}

.checkContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.check {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 36px;
  background: rgb(0, 213, 14);
  border-radius: 100%;
  transition: all 0.3s ease, 
  box-shadow 0.3s ease;

}

.check:hover {
  background: rgb(0, 255, 0);
  box-shadow: 0 0 15px 7px rgba(0, 255, 0, 0.7);
}

.barDivider {
  width: 100%;
  height: 80px;
  background: white;
  position: relative;
  bottom: -2px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;

  @media screen and (max-width: 768px) {
    height: 40px;
  }

  @media screen and (max-width: 425px) {
    height: 20px;
  }
}

.centerText {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    font-size: 56px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #fff;

    @media screen and (max-width: 500px) {
      font-size: 32px;
    }
  }

  > span:nth-child(1) {
    text-align: center;
  }

  > span:nth-child(3) {
    text-align: center;
  }

  > img:nth-child(1) {
    position: absolute;
    top: 80px;
    left: 0;

    @media screen and (max-width: 500px) {
      top: 32px;
      left: -20px;
    }
  }

  > img:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(50deg);

    @media screen and (max-width: 500px) {
      right: -20px;
    }

    @media screen and (max-width: 425px) {
      right: -10px
    }
  }
}