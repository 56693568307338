.root {
  overflow: hidden;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #9dc208;
}

.faleconosco {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 500px) {
    width: 100%;
    align-items: center;
  }
}

.container {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: row;
  color: white;
  width: 100%;
  border-radius: 50px 50px 0 0;
  background: #08824a;
  top: -50px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
}

.container .text{
  margin: 8rem 0 0 0;
  padding: 2rem;
  min-width: 240px;

  @media screen and (max-width: 900px) {
    margin: 3rem 0 2rem 0;
    padding: 2rem 2rem 0 2rem;
  }

  @media screen and (max-width: 425px) {
    margin: 3rem 0 2rem 0;
    padding: 2rem 2rem 0 2rem;
  }
}

.container h4 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: white;

  margin-block-start: 0;
  margin-block-end: 0;
}

.container p {
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  color: white;

  margin-block-start: 0;
  margin-block-end: 0;
}

.map {
  width: 100%;
  height: 75vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 900px) {
    margin-top: 6rem;
  }

  @media screen and (max-width: 768px) {
    height: 50vh;
  }

  @media screen and (max-width: 425px) {
    background-size: cover;
    margin-top: 5rem;
    height: 28vh;
  }

  @media screen and (max-width: 400px) {
    height: 26vh;
  }

  @media screen and (max-width: 375px) {
    height: 31vh;
  }

  @media screen and (max-width: 350px) {
    height: 24vh;
  }

  @media screen and (max-width: 340px) {
    height: 22.5vh;
  }
}

.woman {
  width: 600px;
  height: 100%;
  margin-top: 6rem;

  @media screen and (max-width: 1160px) {
    width: 500px;
    margin-top: 10rem;
  }

  @media screen and (max-width: 1050px) {
    width: 400px;
    margin-top: 13rem;
    margin-right: -3rem;
  }

  @media screen and (max-width: 900px) {
    margin-top: 0;
  }
}

.listPlans {
  color: white;
}

.listPlansTexts {
  text-align: center;
  margin-top: -18rem;
}

.listPlansTexts h1 {
  font-size: 120px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;

  margin-block-end: 0;

  @media screen and (max-width: 1024px) {
    font-size: 72px;
  }

  @media screen and (max-width: 425px) {
    font-size: 48px;
  }
}

.listPlansTexts h6 {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #08824a;

  margin-block-start: 0;

  @media screen and (max-width: 1024px) {
    font-size: 24px;
  }

  @media screen and (max-width: 425px) {
    font-size: 18px; 
  }
}

.listPlans p {
  font-size: 80px;
  margin-bottom: -50px;
}

.listPlans h4{
  font-size: 25px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #08824a;
}

.plansDescription {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px; 

  @media screen and (max-width: 815px) {
    flex-direction: column;
  }
}


.children {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.center {
  overflow-x: scroll;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.bottomCenter {
  justify-content: end;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottomCenterText {
  padding: 1rem;
  justify-content: end;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  margin: -45rem -20rem 20rem 0;
}

.wave {
  position: relative;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  top: -17rem;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(121% + 1.3px);
  height: 237px;
  transform: rotateY(180deg);

  @media screen and (max-width: 1050px) { 
    width: calc(154% + 1.3px);
  }

  @media screen and (max-width: 900px) {
    width: calc(230% + 1.3px);
  }
}

.wave_fill {
  fill: #9dc208;
}

$animationSpeed: 40s;

@media only screen and (max-width: 1370px) {
  .slideTrack {
    //animation: scroll_Card $animationSpeed linear infinite;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 800px) {
  .slideTrack {
    padding-left: 20rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
  .roteador {
    display: none;
  }
}

@media only screen and (max-width: 730px) {
  .slideTrack {
    padding-left: 25rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 635px) {
  .slideTrack {
    padding-left: 30rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 560px) {
  .slideTrack {
    padding-left: 35rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 480px) {
  .slideTrack {
    padding-left: 40rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 400px) {
  .slideTrack {
    padding-left: 45rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (max-width: 330px) {
  .slideTrack {
    padding-left: 50rem !important;
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

.slider {
  overflow-x: auto;
  position: relative;
}

.slideTrack {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.rodape {
  display: flex;
  z-index: 2;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin-top: 7rem;

  .rodapeText {
    display: flex;
    flex-direction: column;
    padding: 0 15% 0 15%;

    @media screen and (max-width: 1024px) {
      align-items: center;
    }

    & > span {
      font-size: 2rem;
      font-family: "Montserrat", sans-serif;
      color: #FFF;

      @media screen and (max-width: 500px) {
        text-align: center;
      }
    }
  }

}

.balaoverde {
  position: absolute;
  z-index: 99999;
  width: 50%;
  bottom: 0;
  right: 0;

  @media screen and (max-width: 1024px) {
    width: 70%;
    bottom: -17%;
    right: 0%;
  }

  @media screen and (max-width: 768px) {
    width: 80%;	
  }

  @media screen and (max-width: 620px) {
    width: 100%;
    bottom: -12%;
  }

  @media screen and (max-width: 425px) {
    bottom: -8%;
  }
}
