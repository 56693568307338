.root {
  margin-top: 6rem;
  background-color: #3d8147;
}

.container {
  color: white;
}

.searchCredenciados {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.searchCredenciados h1 {
  margin-top: 5rem;
  font-family: "Montserrat", sans-serif;

  @media screen and (max-width: 425px) {
    font-size: 26px;
  }
}

.squareBanner img {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchCredenciados input {
  position: relative;
  width: 30rem;
  height: 2rem;
  padding-left: 2rem;
  border: none;
  border-radius: 1rem;

  @media screen and (max-width: 775px) {
    width: 20rem;
  }

  @media screen and (max-width: 425px) {
    width: 16rem;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.searchCredenciados input::placeholder {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;

  @media screen and (max-width: 425px) {
    width: 13.5rem;
  }
}

.searchCredenciados input:focus {
  border: none;
}

.searchCredenciados h4 {
  font-weight: lighter;
}

.familyText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;

  @media screen and (max-width: 775px) {
    flex-direction: column;
  }
}

.familyText .text {
  font-family: "Montserrat", sans-serif;
  max-width: 11rem;
  margin-right: 2rem;

  @media screen and (max-width: 775px) {
    max-width: 30rem;
    margin-right: 0;
    text-align: center;
    padding: 1rem;
  }
}

.familyText .text b {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 6px;
}

.family {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.family img {
  max-width: 500px;

  @media screen and (max-width: 550px) {
    width: 100%;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5rem;
  position: relative;

  @media screen and (max-width: 775px) {
    flex-direction: column;
  }
}

.footerText:last-child {
  position: relative;
}

.footer .footerText {
  margin-left: 3rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: "Montserrat", sans-serif;

  @media screen and (max-width: 775px) {
    margin-left: 0;
    margin-bottom: 2rem;
  }

  .socialText {
    font-weight: 600;
  }

  p {
    align-self: self-end;
    margin-block-start: 0;
    margin-block-end: 0;

    @media screen and (max-width: 775px) {
      align-self: center;
    }
  }
}

.socialIcon {
  display: flex;
  align-items: center;
  gap: 6px;
}

.boleado {
  position: absolute;
  top: -3px;
  right: -30px;
  width: 70px;

  @media screen and (max-width: 775px) {
    right: 0;
    left: 53%;
  }
}
