.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-bottom: 6rem;
  gap: 1rem;
  background-color: #08824a;

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    gap: 4rem;
  }
}

.polygon {
  max-width: 800px;

  @media screen and (max-width: 1280px) {
    max-width: 600px;
  }

  @media screen and (max-width: 1000px) {
    max-width: 400px;
  }

  @media screen and (max-width: 800px) {
    max-width: 300px;
  }

  @media screen and (max-width: 490px) {
    margin-top: 6rem;
  }
}

.banner {
  height: 100%;
  background-color: #08824a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0rem 0rem 4rem;
  margin-bottom: -2px;
  gap: 1rem;

  color: white;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;

  @media screen and (max-width: 950px) {
    flex-direction: column;
  }

  @media screen and (max-width: 700px) {
    padding: 0;
  }

  @media screen and (max-width: 425px) {
    padding: 4rem 0rem 3rem 0rem;
  }
}

.centerText {
  position: relative;
  display: flex;
  flex-direction: column;

  > span {
    font-size: 56px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #fff;

    @media screen and (max-width: 500px) {
      font-size: 32px;
    }
  }

  > span:nth-child(1) {
    text-align: center;
  }

  > span:nth-child(4) {
    margin-left: 8rem;

    @media screen and (max-width: 500px) {
      margin-left: 4rem;
    }
  }

  > span:nth-child(3) {
    text-align: center;
  }

  > img:nth-child(1) {
    position: absolute;
    top: 80px;
    left: 0;

    @media screen and (max-width: 500px) {
      top: 32px;
    }
  }

  > img:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(50deg);

    @media screen and (max-width: 500px) {
      right: -20px;
    }

    @media screen and (max-width: 425px) {
      right: -10px
    }
  }
}

.row {
  display: flex;
  position: relative;
  gap: 0.4rem;
}

.imageBackgroundBalloon{
  position: relative;
  width: 100%;
  height: 100%;

  img {
    max-width: 300px;
  }
}

.containerBox {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.containerText {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83%;
  height: 80%;
  padding-left: 15%;

  span {
    text-align: center;
    margin-top: 50px;
    margin-left: -20px;
    padding: 15px;
  }
}

.containerTopImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: #FAAF2D;
  border-radius: 30px;
  position: absolute;
  top: -12%;
  right: 63%;

  img {
    width: 36px;
    height: 36px;
  }
}
