.footer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  background-color: #08824a;
  padding: 10rem 2rem 5rem 2rem;
  overflow: hidden;

  @media screen and (max-width: 775px) {
    flex-direction: column;
    gap: 1rem;
  }

  @media screen and (max-width: 425px) {
    padding: 2rem 2rem 5rem 2rem;
  }
}

.footerText:last-child {
  position: relative;
}

.footer .footerText{
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FFFFFF;

  font-family: 'Montserrat', sans-serif;
  font-weight: 800;

  @media screen and (max-width: 775px) {
    margin-left: 0;
  }

  .socialIcon {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .socialText {
    font-weight: 600;
  }

  p {
    align-self: self-end;
    margin-block-start: 0;
    margin-block-end: 0;

    @media screen and (max-width: 775px) {
      align-self: center;
    }
  }
}

.roundedCircle {
  position: absolute;
  top: 35%;
  left: 26%;

  width: 300px;
  height: 300px;
  border-radius: 100%;
  background: linear-gradient(135deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 10%), linear-gradient(225deg, rgb(255, 255, 255, 0.4) -140%, rgba(255, 255, 255, 0) 60%)
}

.boleado {
  position: absolute;
  top: -3px;
  right: -30px;
  width: 70px;

  @media screen and (max-width: 775px) {
    right: 0;
    left: 53%;

  };
}