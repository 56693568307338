.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
}

.medalha {
    
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    text-align: center;
    width: 210px;
    display: inline-block;
    cursor: pointer;
    transition: all ease 0.5s;

    @media screen and (max-width: 625px) {
        display: block;
        margin-bottom: 2rem;
    }
}

.medalha:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 15px rgba(255, 255, 255, 0.7);
}

.imagem {
    width: auto;
    height: 100px;
}

.medalhaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.descricao {
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;

    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: bold;

    border: 1px solid white;
    border-radius: 10px;
}

.descricao p {
    display: flex;
    margin: 5px 0;
    color: white;
    font-size: 14px;
    
    font-weight: bolder;
    justify-content: left;
}

.bronze .descricao {
    border: 2px solid #cd7f32;
}

.prata .descricao {
    border: 2px solid #c0c0c0;
}

.ouro .descricao {
    border: 2px solid #ffd700;
}

.diamante .descricao {
    border: 2px solid #b9f2ff;
}

.rubi .descricao {
    border: 2px solid #e0115f;
}

.esmeralda .descricao {
    border: 2px solid #50c878;
}

.tipo {
    font-size: 25px;
    padding: 0 15px 0 15px;
    font-weight: bold;
    margin-top: 10px;
    border: 1px solid #ffffff;
    border-radius: 10px;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
  }
  
  .closeButton {
    position: absolute;
    top: 30px;
    right: 40px;
    background: transparent;
    border: none;
    font-size: 60px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        right: 20px;
        top: 10px;
    }

    @media screen and (max-width: 425px) {
        right: 10px;
        top: 10px;
        
    }
  }
  
  .modalImage {
    max-width: 100%;
    height: auto;
  }
