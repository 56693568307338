.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f3ff;
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20rem;
    margin-right: 2rem;

    @media screen and (max-width: 1160px) {
        margin-bottom: 15rem;
    }

    @media screen and (max-width: 900px) {
        width: 90%;
        margin-right: 2rem;
        margin-left: 2rem;
        margin-bottom: 0;
    }
}

form {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100%;

    .containerForm {
        display: flex;
        gap: 16px;

        @media screen and (max-width: 500px) {
            flex-direction: column;
        }
    }

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        width: 100%;

        label {
            margin-bottom: 0.5rem;
            color: #666666;
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
        }

        input, textarea {
            padding: 0.5rem;
            border: none;
            border-bottom: 1px solid #ccc;
            font-size: 1rem;
            background-color: inherit;
            
            &:focus-visible {
                outline: none;
            }
        }

        textarea {
            resize: vertical;
        } 
    }

    button {
        width: 45%;
        margin-top: 2rem;
        padding: 0.75rem;
        background-color: #218838;
        border: none;
        border-radius: 4px;
        font-size: 1rem;
        color: #fff;
        font-family: "Montserrat", sans-serif;
        cursor: pointer;
        
        &:hover {
            filter: brightness(0.9);
        }
    }
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
}