.boxContainer {
    display: flex;
    flex-direction: column;
}

.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2rem 2rem 0rem 2rem;
    gap: 2rem;

    @media screen and (max-width: 1050px) {
        flex-direction: column;
    }
}

.text {
    color: #08824a;
    max-width: 26rem;
    font-weight: 500;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
}

.image img{
    width: 35rem;
    margin-bottom: -6rem;

    @media screen and (max-width: 1050px) {
        width: 30rem;
    }

    @media screen and (max-width: 500px) {
        width: 18rem;
    }
}