.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -5px;
  height: 100%;
  background-color: #A7C04D;
  padding: 0 4rem 0 4rem;
  gap: 1rem;

  .description{
    font-size: 10px;
    font-weight: lighter;
  }

  .subdescription{
    font-size: 10px;
    font-weight: lighter;
  }

  @media screen and (max-width: 980px) {
    padding: 4rem 0 4rem 0;
  }
}

.obsBanner {
  margin-top: -5rem;
  margin-bottom: -3rem;
  color: white;
}

.cardBanner {
  height: 100%;
  background-color: #A7C04D;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  gap: 1rem;

  @media screen and (max-width: 980px) {
    padding: 0;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 0;
  }

  @media screen and (max-width: 425px) {
    padding: 0;
  }
}

.card {
  display: flex;
  width: 240px;
  height: 3.5rem;
  background-color: #08824a;
  padding: 16px;
  border-radius: 16px 32px 16px 32px;
}

.row {
  display: flex;
  gap: 0.4rem;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-size: 19px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9dc208;
  color: white;
  border: none;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  margin-top: 10px;
}
