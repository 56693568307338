.carousel {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  & > div > button:nth-child(3) {
    z-index: 1;
    position: absolute;
    right: 2%;
    top: 55%;
    background: #9dc208;
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;

    @media screen and (max-width: 425px) {
      top: 60%;
      padding: 4px;
    }
  }

  & > div > button:nth-child(3)::before {
    content: none;
  }

  & > div > button:nth-child(1) {
    z-index: 1;
    position: absolute;
    left: 2%;
    top: 55%;
    background: #9dc208;
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;

    @media screen and (max-width: 425px) {
      top: 60%;
      padding: 4px;
    }
  }

  & > div > button:nth-child(1)::before {
    content: none;
  }
}

.slides {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  min-width: 100%;
  height: 100%;
  margin-top: 6rem;
}

.slide img {
  width: 100%;
  max-height: 750px;
  display: block;
  object-fit: cover;

  @media screen and (max-width: 2561px) {
    max-height: none;
  }
}

.nextButton,
.prevButton {
  position: absolute;
  top: 55%;
  background-color: #9dc208;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 100%;

  @media screen and (max-width: 425px) {
    top: 60%;
    padding: 4px;
  }
}

.nextButton {
  right: 10px;
}

.prevButton {
  left: 10px;
}
