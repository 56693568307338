.bannerTopo img {
  width: 100%;
  margin-top: 3rem;

  @media screen and (max-width: 1024px) {
    margin-top: 5rem;
  }

  @media screen and (max-width: 600px) {
    margin-top: 6rem;
  }
}

.imageSlider{
  justify-content: center;
  margin-top: -5rem;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    margin-top: -2rem;
  }
  
  @media screen and (max-width: 425px) {
    margin-top: -1rem;
  }
}

.slider-image {
  max-width: 80%;
  height: auto;
  margin: 0 auto;
  
}

.slick-slide div {
  display: flex;
  justify-content: center;
}


.listPartners {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -80px;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    margin-top: -60px;
  }

  @media screen and (max-width: 650px) {
    margin-top: -40px;
  }
}

.listPartners img {
  max-width: 250px;

  @media screen and (max-width: 1024px) {
    max-width: 200px;
  }

  @media screen and (max-width: 850px) {
    max-width: 160px;
  }

  @media screen and (max-width: 850px) {
    max-width: 160px;
  }

  @media screen and (max-width: 768px) {
    max-width: 140px;
  }

  @media screen and (max-width: 650px) {
    max-width: 120px;
  }
}

.waveabout {
    position: relative;
}

.partners_effect {
  cursor: pointer;
  transition: all ease 0.5s;
}

.wave {
  position: relative;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(121% + 1.3px);
  height: 237px;
  transform: rotateY(180deg);
}

.wave_fill {
  fill: #08824a;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;

  @media screen and (max-width: 768px) {
    padding: 2rem;
  }

  @media screen and (max-width: 430px) {
    padding: 1rem;
  }

  & > img {
    width: 100%;
  }
}

$animationSpeed: 40s;